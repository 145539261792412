<script setup>
    const props = defineProps({
        "item": { type: Object },
        "type": { type: String },
        "title": { type: String },
        "description": { type: String },
        "to": {},
        "canDelete": { type: Boolean },
    });

    const emit = defineEmits([
        'delete'
    ]);

    // cache fix
</script>

<template>
    <li class="list-group-item pe-0">
        <div class="d-flex justify-content-between">
            <component :is="to ? 'router-link' : 'a'" :to="to" style="text-decoration: none; color: inherit;" v-bind="$attrs">
                <div class="">


                    <div class="font-n5 uppercase tracking-4 text-muted text-truncate">
                        <slot name="type" />
                    </div>
                    <div class="font-n1 fw-medium">
                        <slot name="title" />
                    </div>


                    <template v-if="type">
                        <div class="font-n5 uppercase tracking-4 text-muted text-truncate">
                            {{ type }}
                        </div>
                    </template>
                    <template v-if="title || $slots.title">
                        <div class="font-n1 fw-medium">
                            <span v-if="title">{{ title }}</span>
                            <slot name="title" />
                        </div>
                    </template>

                    <!--
                    <div class="fw-semibold" v-if="title || $slots.title">
                        <span v-if="title">{{ title }}</span>
                        <slot name="title" />
                    </div>
                    -->
                    
                    <div class="mt-1" v-if="description || $slots.description">
                        <span v-if="description">{{ description }}</span>
                        <slot name="description" />
                    </div>

                    <slot />
                </div>
            </component>

            <div class="border-start px-4 d-flex align-items-center justify-content-center" v-if="canDelete">
                <i class="far fa-times" style="font-size: 1.00em; font-weight: 300; color:#A6AABC" @click="emit('delete', item)"></i> 
            </div>
        </div>
    </li>
</template>

<style scoped>
    .list-group-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>